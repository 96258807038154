/* You can add global styles to this file, and also import other style files */
//@include merge-digital-bank-light();
//@include legacy-santander-font();
@import './flexboxgrid.css';
@import '../fonts/fonts.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

h1,
h2,
h3,
h4 {
    font-family: 'SantanderHeadLine', sans-serif;
}

html,
body {
    background-color: #e5e5e5;
    color: #191919;
    padding: 0;
    margin: 0;
    font-family: 'SantanderHeadLine', sans-serif;
}

p,
a {
    font-family: 'SantanderTextLight', sans-serif;
    margin: 0;
    padding: 0;
}

strong, b {
    font-family: 'SantanderHeadLine', sans-serif;
}

.container_header_static {
    position: static;
}

.container_header_fixed {
    position: fixed;
    z-index: 2;
}

.bg-principal {
    background-color: #191919;
    color: #fff;
}

.bg-secondary {
    background-color: #fff;
    color: #191919;
}

.container-all-width {
    width: 100%;
}

.color-white {
    color: rgba(255, 255, 255, 1);
}

.container-content {
    max-width: 1366px;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    //border: 1px solid red;
}

.overflow-hidden {
    overflow: hidden;
}

.py-1 {
    padding-top: 1em;
    padding-bottom: 1em;
}

.py-3 {
    padding-top: 3em;
    padding-bottom: 3em;
}

.pt-3 {
    padding-top: 3em;
}

.py-4 {
    padding-top: 4em;
    padding-bottom: 4em;
}

.pl-0 {
    padding-left: 0;
}

.py-0 {
    padding-right: 0;
}

.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.text-end {
    text-align: end;
}

.text-center {
    text-align: center;
}

.btn_primary {
    text-decoration: none;
    color: #FFF;
    background-color: #191919;
    border-radius: 48px;
    padding: 1em 3em;
    border: 1px solid;
    display: inline-block;
    font-weight: 700;
    transition: background-color .2s;
    cursor: pointer;
    font-family: 'SantanderTextBold', sans-serif;
}

.btn_primary:hover {
    color: #191919;
    background-color: #FFF;
}

.btn_primary_ht {
    text-decoration: none;
    color: #FFF;
    background-color: #191919;
    border-radius: 48px;
    padding: 1em 2em;
    border: 1px solid;
    display: inline-block;
    font-weight: 700;
    transition: background-color .2s;
    cursor: pointer;
    font-family: 'SantanderTextBold', sans-serif;
}

.px-3 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

.btn_primary_ht:hover {
    color: #191919;
    background-color: transparent;
}

.btn_secondary {
    text-decoration: none;
    color: #191919;
    background-color: transparent;
    border-radius: 48px;
    padding: 1em 2em;
    border: 1px solid;
    display: inline-block;
    font-weight: 700;
    transition: background-color .2s;
    cursor: pointer;
    font-family: 'SantanderTextBold', sans-serif;
}

.btn_secondary:hover {
    color: #FFF;
    background-color: #191919;
}

.btn_primary_bgw {
    background-color: #FFF;
    border: 1px solid #CCC;
    box-shadow: none !important;
    text-decoration: none;
    color: #191919;
    border-radius: 48px;
    padding: 1em 3em;
    display: inline-block;
    font-weight: 700;
    transition: background-color .2s;
    cursor: pointer;
    font-family: 'SantanderTextBold', sans-serif;
}

.btn_primary_bgw[disabled] {
    color: #999999;
    background-color: #D9D9D9;
    transition: background-color 0.2s;
}

.header__section__controls {
    .control {
        justify-content: end;

        .text {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 150%;
            margin-right: 30px;
        }

        .arrow-left {
            height: 25px;
            margin-right: 30px;
        }

        .arrow-right {
            height: 25px;
        }
    }

    .title {
        font-style: normal;
        font-weight: 300;
        font-size: 42px;
        line-height: 52.5px;
        margin: 16px 0;

    }

    .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin-top: 10px;
    }
}

.cursor_pointer_style {
    cursor: pointer;
}

.slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-23 9:25:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}