@font-face {
    font-family: 'SantanderTextLight';
    src: url('./santander-text/SantanderText-Light.woff2') format('woff2'),
         url('./santander-text/SantanderText-Light.woff') format('woff'),
         url('./santander-text/SantanderText-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SantanderHeadLine';
    src: url('./santander-headline/SantanderHeadline-Regular.woff2') format('woff2'),
         url('./santander-headline/SantanderHeadline-Regular.woff') format('woff'),
         url('./santander-headline/SantanderHeadline-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SantanderTextBold';
    src: url('./santander-text-bold/SantanderText-Bold.woff2') format('woff2'),
         url('./santander-text-bold/SantanderText-Bold.woff') format('woff'),
         url('./santander-text-bold/SantanderText-Bold.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SantanderTextRegular';
    src: url('./santander-text-regular/SantanderText-Regular.woff2') format('woff2'),
         url('./santander-text-regular/SantanderText-Regular.woff') format('woff'),
         url('./santander-text-regular/SantanderText-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}